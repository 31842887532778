import React, { useState, useEffect } from "react";
import * as apiClient from "../../common/apiClient";
import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify

} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";

import { GridDetail } from './GridDetail';
import { TextField } from "@material-ui/core";

export const GoodsInwardOrderEdit = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect = useRedirect();
  const [gridData, setGridData] = useState([{ lineNumber: 1, productCode: null, productName: null, uomId: 0, price: null, qty: null, totalAmount: null, attributes: [] }]);
  const[isLoading,SetIsLoading]=useState(true)
  const[transactionNo,SettransactionNo]=useState("")
  const [taxType, setTaxType] = useState(0);
  useEffect(() => {
    setGridData(gridData)
  });



  useEffect(() => {
    
    loadData()
  }, []);


  const loadData =  async() => {
    SetIsLoading(true)
    await apiClient.getInwardOrder(props.id).then(res => {
      debugger
      let data=JSON.parse(res[0].data);
      setGridData(data[0].lineItems)
      setTaxType(data[0].taxType)
      SettransactionNo(data[0].purchaseNo)
      SetIsLoading(false)
    }).catch(err => {
      console.log(err);
      SetIsLoading(false)
    });
    
  }
  const handleChange = (indata) => {

    setGridData(indata);
  }
  
  const loadTaxType = async (e) => {
    
    await apiClient.getAccountData(e).then(res => { 
      setTaxType(res.gstType);  
    }).catch(err => {
      console.log(err);
    });
    
  }
  const handleSubmit = async (formdata) => {
    //alert("c");
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }

    let data: any = {};
    data.id=formdata.id;
    data.branchId=formdata.branchId;
    data.vendorId=formdata.vendorId;
    data.documentNo=formdata.documentNo;
    data.goodsInwardDate=formdata.goodsInwardDate;
    data.taxType=taxType; 
    data.remarks=formdata.remarks;  
    data.purchaseOrderId =formdata.purchaseOrderId ;  
    data.inwardTypeId =formdata.inwardTypeId ;  
    data.lineItems=[...gridData];
    // let m = moment(new Date());
    
    // data. modifyDate=  m.toISOString()
     data.createdBy=  userId
    apiClient.addeditGoodsInwardOrder( data).then(res => {
      if (res[0].id > 0) {
        notify(res[0].remarks)
        redirect("/goods-inward-orders");
      }
      else {
        notify(res[0].remarks, "warning")

      }
      //console.log(res);
      //props.onClose();
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <Edit title="Goods Inward Order Edit" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit} toolbar={<FormToolbar onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" >
        <div className="transaction-header">
          Goods Order Detail ::-{transactionNo}   
        </div>
        
        <DateInput source={"goodsInwardDate"} label={"Goods Inward Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_4_input}  initialValue={new Date()}/>         
        <ReferenceInput label="Inward Type" fullWidth={true} formClassName={classes.two_4_input}
          perPage={5000} source="inwardTypeId"
          filter={{ type: 'PurType' }}
          reference="lookups" 
          disabled
          initialValue={158}
          >
          <SelectInput optionText="name" variant="outlined"  validate={[required()]} initialValue={158}/> 
        </ReferenceInput>  
        
        
        <FormDataConsumer  fullWidth={true} formClassName={classes.three_4_input}>
          {({ formData, ...rest }) =>
            formData.inwardTypeId &&
             <ReferenceInput label={formData.inwardTypeId != 158?"Franchise":"Supplier"} fullWidth={true} formClassName={classes.three_4_input}
            perPage={5000} source="vendorId"
            onChange={(e)=>loadTaxType(e)}
            disabled
            filter={{ accountType: formData.inwardTypeId != 158?101:100 }}
            reference="accounts" > 
            <AutocompleteInput optionText="accountName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
            </ReferenceInput> 
        
          }
        </FormDataConsumer>
        
        {/* <ReferenceInput label="Supplier" fullWidth={true} formClassName={classes.two_4_input}
          perPage={5000} source="vendorId"
          onChange={(e)=>loadTaxType(e)}
          reference="suppliers" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          {/* <AutocompleteInput optionText="supplierName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>   */} 
        <ReferenceInput label="Warehouse" fullWidth={true} formClassName={classes.last_4_input}
          perPage={5000} source="branchId"
          filter={{ userId:localStorage.getItem("userId") }}
          reference="company-branches" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>  
        <ReferenceInput label="Purchase Order" fullWidth={true} formClassName={classes.one_4_input}
          perPage={5000} source="purchaseOrderId"
          disabled
          reference="purchase-orders" >
          <SelectInput optionText="purchaseNo" variant="outlined"  validate={[required()]} />
          {/* <AutocompleteInput optionText="purchaseNo" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} /> */}
        </ReferenceInput>  
        
        <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.two_4_input} />
       
        <TextInput source="remarks" label="Remarks" initialValue={""} fullWidth={true}  /> 
        {!isLoading && <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail {...formDataProps} gridData={gridData} taxType={taxType}  handleChange ={handleChange}/>
              )}
            </FormDataConsumer>
}
             
            
              
                    </SimpleForm>
    </Edit>
  );
};
