import React, { useCallback } from 'react';
import moment from 'moment';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    FormDataConsumer,
    choices, Button,
    Edit, SimpleForm, TextInput, ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";


const PostCreateToolbar = props => (

    <Toolbar {...props}>
        <SaveButton submitOnEnter={true} onSave={props.onSave} />
        <Button
            variant="contained" color="primary"
            label="Back" size="medium"
            startIcon={<CancelIcon />}
            style={{ marginLeft: "30px" }}
            onClick={props.onCancel}
        />
    </Toolbar>
);
const EditExpense = props => {

    const classes = useStyles({});
    const handleSubmit = async (formdata) => {
        console.log(formdata);

        // let data = {
        //     "configId": parseInt(props.configId),
        //     "docType": formdata.docType,
        //     "docValue": parseInt(formdata.docValue),
        //     "isActive":true,

        // }
        let m = moment(new Date());
        let user = localStorage.getItem("userId");
        if (user) {
            user = parseInt(user);
        }

        let data = {
            accountId: parseInt(props.accountId),
            addressType: parseInt(formdata.addressType),
            address1: formdata.address1,
            address1Hindi: formdata.address1Hindi,
            address2: formdata.address2,
            address2Hindi: formdata.address2Hindi,
            cityId: parseInt(formdata.cityId),
            stateId: parseInt(formdata.stateId),

            countryId: parseInt(formdata.countryId),
            zipCode: formdata.zipCode,
            createdBy: user,
            createdDate: m.toISOString(),
            modifyBy: user,
            modifyDate: m.toISOString(),
            isDefault: true,
            isActive: true
        }


        let _data = { ...formdata };  // Copy the formdata

// Add modification info
_data.modifyBy = user;
_data.modifyDate = m.toISOString();

// Check for 'verifyBy' and assign it if exists in formdata
if (_data.hasOwnProperty("verifyBy") && _data.verifyBy) {
    _data.verifyBy = parseInt(formdata.verifyBy);  // Ensure it's an integer
}

// Handle 'verifyDate' if it's present in the formdata
if (_data.hasOwnProperty("verifyDate") && _data.verifyDate) {
    let m = moment(_data.verifyDate);
    _data.verifyDate = m.toISOString();
}

// Handle 'paidOn' if it's present in the formdata
if (_data.hasOwnProperty("paidOn") && _data.paidOn) {
    let m = moment(_data.paidOn);
    _data.paidOn = m.toISOString();
}

Object.keys(_data).forEach(key => {
    if (_data[key] === null || _data[key] === undefined ) {
        delete _data[key];  // Delete the property if its value is null, undefined, or an empty string
    }
});

            apiClient.updateEntiyRecord("daily-expenses", _data).then(res => {
                console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });



        }
        return (
            <Edit {...props} id={props.expenseDetailId} resource="daily-expenses">
                <SimpleForm onSubmit={handleSubmit}
                    toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel} />} variant="outlined">
                    <ReferenceInput label="Type"
                        fullWidth={true}
                        perPage={5000} source="expnesesTypeId"
                        disabled
                        reference="lookups" >
                        <SelectInput optionText="name" variant="outlined" fullWidth={true} />
                    </ReferenceInput>
                    <TextInput source="expensesDetails" label="Details" fullWidth={true} disabled />
                    <TextInput source="expenseAmount" label="Amount" fullWidth={true} disabled />


                    <BooleanInput label="Is Approved" source="isApproved" fullWidth={true} initialValue={false} formClassName={classes.first_inline_input} />
                    <NumberInput validate={[required()]} source="expenseAmountApproved" label="Approved Amount" fullWidth={true} formClassName={classes.last_inline_input} />
                    <TextInput source="approverRemarks" label="Approver remarks" fullWidth={true}
                    />
                    <ReferenceInput label="Verified By"
                        formClassName={classes.first_three_input}
                        fullWidth={true}
                        perPage={5000} source="verifyBy"
                        sort={{ field: 'firstName', order: 'ASC' }}
  filter={{ typeId: 270 }}

                        reference="users" >
                        <SelectInput optionText="firstName" variant="outlined" fullWidth={true} />
                    </ReferenceInput>
                    <DateInput source="verifyDate" label="Verified On" fullWidth={true} />
                    <DateInput source="paidOn" label="Paid On" fullWidth={true} />

                </SimpleForm>
            </Edit>

        );
    }
    export default EditExpense; 