import React, { useState } from 'react';
import {
    required,
    Create,
    SelectInput,
    ReferenceInput,
    SimpleForm,
    TextInput,
    FileInput,
    FileField
} from 'react-admin';
import moment from 'moment';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { useStyles } from "../../../formStyles";

const AccountDocCreate = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (formData) => {
        try {
            setIsLoading(true);
            let user = localStorage.getItem("userId");
            user = user ? parseInt(user) : null;
            const currentDate = moment().toISOString();

            // Upload file if exists
            let documentUrl = null;
            if (formData.imageUrl && formData.imageUrl.rawFile) {
                debugger
                const uploadResponse = await apiClient.UploadFileV1("imageUrl", formData.imageUrl);
                
                documentUrl = uploadResponse.url;
            }

            // Prepare Data
            const data = {
                accountId: parseInt(props.accountId),
                typeId: formData.typeId,
                remarks: formData.remarks,
                docUrl: documentUrl,
                createdBy: user,
                createdDate: currentDate,
                modifyBy: user,
                modifyDate: currentDate,
            };

            // API Call to Save Document
            await apiClient.addEntiyRecord("account-docs", data);
            setIsLoading(false);
            props.onClose();
        } catch (error) {
            console.error("Error submitting form:", error);
            setIsLoading(false);
        }
    };

    return (
        <Create {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                initialValues={{}}
                toolbar={
                    <PopupCreateToolbar
                        loading={isLoading}
                        label="Save"
                        onSave={handleSubmit}
                        onClose={props.onClose}
                        onCancel={props.onCancel}
                    />
                }
                variant="outlined"
            >
                {/* Document Type Dropdown */}
                <ReferenceInput
                    label="Document Type"
                    fullWidth
                    perPage={5000}
                    source="typeId"
                    filter={{ type: 'DT' }}
                    reference="lookups"
                >
                    <SelectInput optionText="name" validate={[required()]} variant="outlined" fullWidth />
                </ReferenceInput>

                {/* Remarks Input */}
                <TextInput source="remarks" label="Remarks" validate={[required()]} fullWidth />

                {/* File Upload */}
                <FileInput source="imageUrl" label="Document" fullWidth validate={[required()]}>
                    <FileField source="src" title="title" />
                </FileInput>

            </SimpleForm>
        </Create>
    );
};

export default AccountDocCreate;
