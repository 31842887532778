import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  useRefresh,
  ReferenceManyField, ReferenceField, Datagrid,
  TextField,
  DateField,
  NumberInput,
  Create
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common/CDeleteButton";
import CompanyDocuments from "./EditExpense";

const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 650,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}
export const userStyles = makeStyles(theme => ({
  main: {
      display: "flex",
      justifyContent: "space-between",
  },
  item: {
      width: 100,
  },
}));

export const TPAEditButton = props => {
  const setHospitalDetId = () => {
      props.setHospitalDetEdit(props.record.id);
  }
  return (
      <Button
          color="secondary" disableElevation
          size="medium"
          
          onClick={setHospitalDetId}
          startIcon={<EditIcon />}>Edit</Button>
  )
};

export const AccountCreate = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  let typeId= localStorage.getItem("accounttype");
  
  if(typeId)
  {
    typeId=typeId;
  }
  else
  {
    typeId=0;
  }
  const classes = useStyles({});
  const refresh = useRefresh();

  const initialValues = {
    
    accountCode:"-",
    accountType: typeId==1?101:100,
    statusId:typeId==1?null:125
    
    
    
}

  return (
    <React.Fragment>
    <Create 
    
    title={ parseInt(typeId)===1?"Franchise Create":"Supplier Create"} 
    {...propsObj}>
    
   <TabbedForm variant="outlined" redirect="list" initialValues={initialValues} toolbar={<CreateToolbar {...propsObj} />}>
          <FormTab value="DETAILS" label={parseInt(typeId)===1?"Franchise Detail":"Supplier Detail"}>
          <ReferenceInput variant="outlined" label="Parent" source="parentAccountId"
          formClassName={classes.first_inline_input} 
                reference="accounts" perPage={500}
                // filter={{ "countryId": formData.countryId }}
                
                disabled
                fullWidth={true}>
                <SelectInput optionText="accountName"  formClassName={classes.first_inline_input} />
            </ReferenceInput>
            <ReferenceInput label="Type" formClassName={classes.last_inline_input} 
            fullWidth={true}
                    perPage={5000} source="accountType"
                    filter={{ type: 'ACT' }}
                    reference="lookups"
                    disabled
                     >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true}  formClassName={classes.last_inline_input}  disabled />
                </ReferenceInput>
          
          <TextInput validate={[required()]} source="accountName" 
          label={parseInt(typeId)===1?"Franchise Name":"Supplier  Name"}
          
          fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput validate={[required()]} source="accountNameHindi" 
          label={parseInt(typeId)===1?"Franchise Name Hindi":"Supplier  Name Hindi"}
           fullWidth={true} formClassName={classes.last_inline_input} />
          
          <TextInput  source="contactName" label="Contact Name " fullWidth={true} formClassName={classes.one_three_input} /> 
          <TextInput  source="contactNameHindi" label="Contact Name Hindi " fullWidth={true} formClassName={classes.two_three_input} /> 
          <TextInput  source="mobilePhone" label="Mobile Phone " fullWidth={true} formClassName={classes.last_three_input} />

          <TextInput  source="officePhone" label="Office Phone " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="email" label="Email " fullWidth={true} formClassName={classes.two_three_input} />
          <ReferenceInput label="GST Type" formClassName={classes.last_three_input} 
                    perPage={5000} source="gstType" 
                    fullWidth={true}
                    reference="tax-heads" >
                    <SelectInput optionText="taxName" variant="outlined"  fullWidth={true} formClassName={classes.last_three_input}  />
          </ReferenceInput>
          <TextInput  source="gstNo" label="GST NO " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="bankAccountNo" label="Bank Account No " fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput  source="bankName" label="Bank Name " fullWidth={true} formClassName={classes.last_three_input} />
          <TextInput  source="bankAddress" label="Bank Address " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="ifscCode" label="IFSC Code " fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput  source="panNo" label="PAN No " fullWidth={true} formClassName={classes.last_three_input} /> 
          
          
          
          {typeId===1 && <TextInput  source="latitude" label="Latitude " fullWidth={true} formClassName={classes.one_three_input} />}
          {typeId===1 &&<TextInput  source="longitude" label="Longitude " fullWidth={true} formClassName={classes.two_three_input} />  }
          
         {typeId===1 && <ReferenceInput label="Status" formClassName={classes.last_three_input} 
            fullWidth={true}
            validate={[required()]}
                    perPage={5000} source="statusId"
                    filter={{ type: 'AST' }}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true}  formClassName={classes.last_three_input}  validate={[required()]} />
          </ReferenceInput>
}
<TextInput  source="rating" label="Rating " fullWidth={true} formClassName={classes.one_three_input} />
<div></div>
<NumberInput  source="openingBal" label="Opening Bal " fullWidth={true} formClassName={classes.one_three_input}  />
<NumberInput  source="closingBal" label="Closing Bal " fullWidth={true} formClassName={classes.two_three_input} /> 
<DateInput source="closingBalEffectFrom" label="Closing Bal Effect From" formClassName={classes.last_three_input}/>
          <BooleanInput label="Is Active" source="isActive" fullWidth={true} formClassName={typeId===1?classes.one_three_input:classes.one_three_input} />
              
          </FormTab>
           
          
        </TabbedForm>


    </Create>
 
</React.Fragment>
  );
};
