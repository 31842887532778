import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput,NumberInput, BooleanInput, required, maxLength, minLength,DateInput,AutocompleteInput } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
const initialValues = {
    pan: "",
    adharNo: '',
    bankName:"" ,
    bankAcNo: "",
    ifscCode: "",
    basicSalary:0,
    monthlyTax:0,
    dol:null,
    doj:null
}

export const EmployeeCreate = props => {
    // const defaultValues = { is_active: true, faculty_type: 'Teacher' }
    return (
        <React.Fragment>
            <Create title="Edit Employee" {...props}>
                <SimpleForm warnWhenUnsavedChanges redirect="list" toolbar={<FormToolbar {...props} />} initialValues={initialValues}
                    variant="outlined" >
                        <TextInput source="code"  fullWidth={true} validate={[required()]} />
                    <TextInput source="name" label="Employee Name" fullWidth={true} validate={[required()]} />
                     
                    <ReferenceInput label="Type" fullWidth={true} source="typeId" filter={{ type: 'ET' }} reference="lookups" >
                       <SelectInput optionText="name" variant="outlined"   />
                    </ReferenceInput>
       
                    
                    <ReferenceInput perPage={25} sort={{ field: 'firstName', order: 'ASC' }}
                    filter={{ typeId: 270 }}
                    filterToQuery={searchText => ({ 'firstName~like': searchText })}
                    label="User" source="userId" reference="users" fullWidth={true} >
            <AutocompleteInput optionText="firstName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />                  
         </ReferenceInput> 
                    <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} />
                    <TextInput source="designation" label="Designation" fullWidth={true}  />  
                    <TextInput source="mobileNo" label="Mobile No" fullWidth={true} validate={[required()]}   initialValue={""}/>
                    <TextInput source="altMobileNo" label="Alternate Mobile No" fullWidth={true} initialValue={""} />
                    <TextInput source="email" label="Email Address" fullWidth={true} initialValue={""} />
                    <TextInput source="add1" label="Address1" fullWidth={true} validate={[required()]}   initialValue={""} />
                    <TextInput source="add2" label="Address2" fullWidth={true}  initialValue={""}  />
                    <TextInput source="city" label="City" fullWidth={true}   initialValue={""} />
                    <TextInput source="state" label="State" fullWidth={true}  initialValue={""}  />
                    <TextInput source="zip" label="Pincode" fullWidth={true}  initialValue={""} />
                    
                    <TextInput source="pan" label="PAN" fullWidth={true}  />
                    <TextInput source="adharNo" label="Adhaar No" fullWidth={true}  /> 
                    <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true}  />
                    <TextInput source="bankName" label="Bank Name" fullWidth={true} />
                    <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} />
                    <DateInput source="dol" label="Date of Leaving" fullWidth={true}  />
                    <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true}  />
                    <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} />
                     
                    
                    <BooleanInput source="isActive" label="Is Active" fullWidth={true} validate={[required()]} />
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}