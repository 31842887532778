import React from "react";
import {
  
  Edit,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import Companyfields from "./Companyfields";
export const UsersEdit = (props) => {
  const classes = useStyles({});
  const initialValues={
    deviceId:"",
cropDetail:"",
landArea:"",
lastName:"",
profilePicUrl:"",
mobile:"",
address:"",
designation:"",
firstName :""
  }

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <Edit title="User Edit" {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined"  initialValues={initialValues}>
      <TextInput source="code"  validate={[required()]} formClassName={classes.one_three_input}/>
      <TextInput source="userName" formClassName={classes.two_three_input}  />
      <PasswordInput source="password" formClassName={classes.last_three_input}  />
            <div> 
            </div>
            <TextInput source="firstName" validate={[required()]} formClassName={classes.one_three_input} label="First Name" />
            <TextInput source="lastName"formClassName={classes.two_three_input}  label="Last Name"/>
            <div></div>
            
      <ReferenceInput label="Role" source="roleTypeId" reference="role-types" formClassName={classes.one_three_input}>
              <SelectInput optionText="roleName" validate={[required()]} />
      </ReferenceInput>
      <ReferenceInput label="Type" formClassName={classes.two_three_input} 
                    perPage={5000} source="typeId"
                    filter={{ type: 'USR' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"  formClassName={classes.one_three_input}  />
                </ReferenceInput>
       
        <DateInput source="startDate" formClassName={classes.last_three_input}/>
        <DateInput source="endDate" formClassName={classes.one_three_input}  />
        
        <NumberInput source="allowEditDays" label="Allow Editing For Days " formClassName={classes.two_three_input}  validate={[required()]} initialValue={1} />
                <NumberInput source="allowAddDays" label="Allow Deletion For Days " formClassName={classes.last_three_input} validate={[required()]} initialValue={1} />
        <FormDataConsumer formClassName={classes.one_three_input}>
          {({ formData, ...rest }) =>
            formData.id && 
            
            <ReferenceInput  perPage={2000} label="Approver" source="approverId" reference="users" variant="outlined" formClassName={classes.one_three_input} filterToQuery={(id) => ({ 'id~neq': formData.id })} sort={{ field: 'userName', order: 'ASC' }}v>
            <SelectInput optionText="userName" formClassName={classes.one_three_input} variant="outlined" validate={[required()]} />
          </ReferenceInput> 
          }
        </FormDataConsumer>
        
        
      
        {/* <BooleanInput source="isSuperUser" validate={[required()]}  label="Super User" formClassName={classes.two_three_input}  />  */}
        <TextInput source="email" formClassName={classes.two_three_input} initialValue={""}  />
        
        <TextInput source="mobile" formClassName={classes.last_three_input} initialValue={""}  />        
        <BooleanInput source="isActive" validate={[required()]}  label="Active"  formClassName={classes.one_three_input}  />  
        <FormDataConsumer>
                    {(formDataProps) => <Companyfields {...formDataProps} />}
                </FormDataConsumer>
        
      </SimpleForm>
    </Edit>
  );
};
