import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';


import EditIcon from '@material-ui/icons/Edit';
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  useRefresh,
  ReferenceManyField, ReferenceField, Datagrid,
  TextField,
  DateField,
  NumberInput,
  FormDataConsumer
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common/CDeleteButton";
import AccountAddressFields from "./AccountAddressFields";
import DocumentsCreate from "./docs/DocumentsCreate";
import AccountDocCreate from './docs/AccountDocCreate';

const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      width: 650,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.url && record.url.length > 0)
    return (
      <a href={record.url}>View File</a>
    )
  else {
    return null
  }
}
const DisplayDocFileLink = (props) => {
  let { record } = props;
  
  if (record.docUrl && record.docUrl.length > 0)
    return (
      <a href={record.docUrl}>View File</a>
    )
  else {
    return null
  }
}
const DisplayImage = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <div style={{width:"100px",height:"100px"}}> 
        <img                    src={record[source]}
                      width="100%"
                      height="100%"
                      
        /> </div>
    )
  else {
    return null
  }
}
export const userStyles = makeStyles(theme => ({
  main: {
      display: "flex",
      justifyContent: "space-between",
  },
  item: {
      width: 100,
  },
}));

export const EditDocument = props => {

  const setRecordEdit = () => {
      props.setRecordEdit(props.record.id);
  }
  return (
      <Button
          color="secondary" disableElevation
          size="medium"
          
          onClick={setRecordEdit}
          startIcon={<EditIcon />}>Edit</Button>
  )
};

export const AccountEdit = (props) => {
  const history = useHistory(); // instead of useNavigate


  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  let typeId= localStorage.getItem("accounttype");
  
  if(typeId)
  {
    typeId=typeId;
  }
  else
  {
    typeId=0;
  }
  const classes = useStyles({});
  
  const [isAddress, setIsAddress] = useState(false);
  const [isLicense, setIsLicense] = useState(false);
  const [isLicenseEdit, setIsLicenseEdit] = useState(false);
  const [isAddLedger, setIsAddLedger] = useState(false);
  
  const [isImageType, setIsImageType] = useState(0);
  const [recordId, setRecordId] = useState(null);
  const myclasses = myStyles({});
  const refresh = useRefresh();
  
  const setRecordEdit = (props) => {
    console.log("props",props)
    setRecordId(props);
    setIsLicenseEdit(true);
  }
const closeEdit = () => {
  setIsLicenseEdit(false);
  setIsAddress(false);
  setIsLicense(false);
  setIsAddLedger(false);
  refresh();
}


const onCancel = () => {
  setRecordId(null);
  setIsAddLedger(false);
  setIsAddress(false);
  setIsLicense(false);
}

  const handleClose = useCallback(() => {
    setIsAddress(false);
    setIsLicense(false);
    
}, []);
const handleSuccess = () => {
  alert("")
  if (parseInt(typeId)===1)
  {
    history.push('/accounts?type=1');
  }
  else
  {
    
    history.push('/accounts?type=2');
  }
  

 // navigate('/accounts?type=1');  // Redirect to the accounts list page
};
  return (
    <React.Fragment>
    <Edit 
    onSave={handleSuccess}
    title={ parseInt(typeId)===1?"Franchise Edit":"Supplier Edit"} 
     {...propsObj}>
    
   <TabbedForm variant="outlined"
    onSuccess={handleSuccess}
     toolbar={<CreateToolbar {...propsObj} />}>
          {/* <FormTab value="DETAILS" label="Franchise Detail">
          <ReferenceInput variant="outlined" label="Parent" source="parentAccountId"
          formClassName={classes.first_inline_input} 
                reference="accounts" perPage={500}
                // filter={{ "countryId": formData.countryId }}
                
                
                fullWidth={true}>
                <SelectInput optionText="accountName"  formClassName={classes.first_inline_input} />
            </ReferenceInput>
            <ReferenceInput label="Type" formClassName={classes.last_inline_input} 
            fullWidth={true}
                    perPage={5000} source="accountType"
                    filter={{ type: 'ACT' }}
                    disabled
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true}  formClassName={classes.last_inline_input} disabled />
                </ReferenceInput>
          
          <TextInput validate={[required()]} source="accountName" label="Account Name" fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput validate={[required()]} source="accountNameHindi" label="Account Name Hindi" fullWidth={true} formClassName={classes.last_inline_input} />
          
          <TextInput  source="contactName" label="Contact Name " fullWidth={true} formClassName={classes.one_three_input} /> 
          <TextInput  source="contactNameHindi" label="Contact Name Hindi " fullWidth={true} formClassName={classes.two_three_input} /> 
          <TextInput  source="mobilePhone" label="Mobile Phone " fullWidth={true} formClassName={classes.last_three_input} />

          <TextInput  source="officePhone" label="Office Phone " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="email" label="Email " fullWidth={true} formClassName={classes.two_three_input} />
          <ReferenceInput label="GST Type" formClassName={classes.last_three_input} 
                    perPage={5000} source="gstType" 
                    fullWidth={true}
                    reference="tax-heads" >
                    <SelectInput optionText="taxName" variant="outlined"  fullWidth={true} formClassName={classes.last_three_input}  />
          </ReferenceInput>
          <TextInput  source="gstNo" label="GST NO " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="bankAccountNo" label="Bank Account No " fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput  source="bankName" label="Bank Name " fullWidth={true} formClassName={classes.last_three_input} />
          <TextInput  source="bankAddress" label="Bank Address " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="ifscCode" label="IFSC Code " fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput  source="panNo" label="PAN No " fullWidth={true} formClassName={classes.last_three_input} /> 
          
          
          
          <TextInput  source="latitude" label="Latitude " fullWidth={true} formClassName={classes.one_three_input} />
          <TextInput  source="longitude" label="Longitude " fullWidth={true} formClassName={classes.two_three_input} /> 
          <ReferenceInput label="Status" formClassName={classes.last_three_input} 
            fullWidth={true}
                    perPage={50000} source="statusId" validate={[required()]}
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{ type: 'AST' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true}  formClassName={classes.last_three_input}  validate={[required()]}/>
          </ReferenceInput>
          <BooleanInput label="Is Active" source="isActive" fullWidth={true} formClassName={classes.one_three_input} />
          <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            formData.statusId &&  (formData.statusId===143) &&
            
            <TextInput validate={[required()]}   variant="outlined" source="level1Remarks" multiline label="Level 1 Remarks" fullWidth={true} />
          }
        </FormDataConsumer>


        <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            formData.statusId &&  (formData.statusId===127) &&
            
            <TextInput validate={[required()]}   variant="outlined" source="level1RejectRemarks" multiline label="Level 1 Remarks" fullWidth={true} />
          }
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            formData.statusId &&  (formData.statusId===144  || formData.statusId===140 || formData.statusId==145)  && 
            <TextInput disabled  variant="outlined" source="level1Remarks" multiline label="Level 1 Remarks" fullWidth={true} />
          }
        </FormDataConsumer>
        <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            formData.statusId &&  (formData.statusId===144 || formData.statusId===145) && 
            <TextInput source="level1RejectRemarks" variant="outlined" disabled multiline label="Level 1 Rejected Remarks" fullWidth={true} />
          }
        </FormDataConsumer>

        <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            formData.statusId &&  (formData.statusId===144) && 
            <TextInput validate={[required()]} source="level2Remarks" variant="outlined"  multiline label="Level 2 Remarks" fullWidth={true} />
          }
        </FormDataConsumer>

        

        <FormDataConsumer fullWidth={true} >
          {({ formData, ...rest }) =>
            formData.statusId &&  (formData.statusId===145) && 
            <TextInput validate={[required()]} source="level2RejectRemarks" variant="outlined"  multiline label="Level 2 Remarks" fullWidth={true} />
          }
        </FormDataConsumer>
              
          </FormTab> */}
           <FormTab value="DETAILS" label={parseInt(typeId)===1?"Franchise Detail":"Supplier Detail"}>
          <ReferenceInput variant="outlined" label="Parent" source="parentAccountId"
          formClassName={classes.first_inline_input} 
                reference="accounts" perPage={500}
                // filter={{ "countryId": formData.countryId }}
                
                disabled
                fullWidth={true}>
                <SelectInput optionText="accountName"  formClassName={classes.first_inline_input} />
            </ReferenceInput>
            <ReferenceInput label="Type" formClassName={classes.last_inline_input} 
            fullWidth={true}
                    perPage={5000} source="accountType"
                    filter={{ type: 'ACT' }}
                    reference="lookups"
                    disabled
                     >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true}  formClassName={classes.last_inline_input}  disabled />
                </ReferenceInput>
          
          <TextInput validate={[required()]} source="accountName" 
          label={parseInt(typeId)===1?"Franchise Name":"Supplier  Name"}
          
          fullWidth={true} formClassName={classes.first_inline_input} />
          <TextInput validate={[required()]} source="accountNameHindi" 
          label={parseInt(typeId)===1?"Franchise Name Hindi":"Supplier  Name Hindi"}
           fullWidth={true} formClassName={classes.last_inline_input} />
          
          <TextInput  source="contactName" label="Contact Name " fullWidth={true} formClassName={classes.one_three_input} /> 
          <TextInput  source="contactNameHindi" label="Contact Name Hindi " fullWidth={true} formClassName={classes.two_three_input} /> 
          <TextInput  source="mobilePhone" label="Mobile Phone " fullWidth={true} formClassName={classes.last_three_input} />

          <TextInput  source="officePhone" label="Office Phone " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="email" label="Email " fullWidth={true} formClassName={classes.two_three_input} />
          <ReferenceInput label="GST Type" formClassName={classes.last_three_input} 
                    perPage={5000} source="gstType" 
                    fullWidth={true}
                    reference="tax-heads" >
                    <SelectInput optionText="taxName" variant="outlined"  fullWidth={true} formClassName={classes.last_three_input}  />
          </ReferenceInput>
          <TextInput  source="gstNo" label="GST NO " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="bankAccountNo" label="Bank Account No " fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput  source="bankName" label="Bank Name " fullWidth={true} formClassName={classes.last_three_input} />
          <TextInput  source="bankAddress" label="Bank Address " fullWidth={true} formClassName={classes.one_three_input} />

          <TextInput  source="ifscCode" label="IFSC Code " fullWidth={true} formClassName={classes.two_three_input} />
          <TextInput  source="panNo" label="PAN No " fullWidth={true} formClassName={classes.last_three_input} /> 
          
          
          
          {typeId==1 && <TextInput  source="latitude" label="Latitude " fullWidth={true} formClassName={classes.one_three_input} />}
          {typeId==1 &&<TextInput  source="longitude" label="Longitude " fullWidth={true} formClassName={classes.two_three_input} />  }
          
         {typeId==1 && <ReferenceInput label="Status" formClassName={classes.last_three_input} 
            fullWidth={true}
            validate={[required()]}
                    perPage={5000} source="statusId"
                    filter={{ type: 'AST' }}
                    sort={{ field: 'name', order: 'ASC' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined" fullWidth={true}  formClassName={classes.last_three_input}  validate={[required()]} />
          </ReferenceInput>
}
<TextInput  source="rating" label="Rating " fullWidth={true} formClassName={classes.one_three_input} />
<div></div>
<NumberInput  source="openingBal" label="Opening Bal " fullWidth={true} formClassName={classes.one_three_input}  />
<NumberInput  source="closingBal" label="Closing Bal " fullWidth={true} formClassName={classes.two_three_input} /> 
<DateInput source="closingBalEffectFrom" label="Closing Bal Effect From" formClassName={classes.last_three_input}/>

          <BooleanInput label="Is Active" source="isActive" fullWidth={true} formClassName={typeId===1?classes.one_three_input:classes.one_three_input} />
              
          </FormTab>
           
          <FormTab label="Address">
                        <Button variant="contained" onClick={() => { setIsAddress(true); }}
                            color="secondary">
                            Add Address
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="account-details"
                            perPage={500}
                            //filter={{ targetRef: props.id }}
                            target="accountId">
                            <Datagrid>
                                <VSDeleteButton {...props} />
                                {/* <TPAEditButton setHospitalDetEdit={setgridRecordEdit} /> */}
                                
                                <ReferenceField source="addressType" reference="lookups" label="Address Type"  link={false}>
                                    <TextField source="name" />
                                  </ReferenceField>
                                <TextField label="Address 1" source="address1" />
                                <TextField label="Address 2" source="address2" />
                                <TextField label="Address 1 Hindi" source="address1Hindi" />
                                <TextField label="Address 2 Hindi" source="address2Hindi" />
                                <TextField label="Zipcode" source="zipCode" />
                                <ReferenceField source="countryId" reference="countries" label="Country"  link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField source="stateId" reference="states" label="State"  link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField source="cityId" reference="cities" label="City"  link={false}>
                                <TextField source="name" />
                            </ReferenceField>
                            </Datagrid>
                        </ReferenceManyField>
          </FormTab>

         {typeId==1 && <FormTab label="License Detail ">
                        <Button variant="contained" onClick={() => { setIsLicense (true); setIsImageType(114) }}
                            color="secondary">
                            Add License 
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="account-image-maps"
                            perPage={500}
                            filter={{ imageTypeId:114}}
                            target="accountId">
                            <Datagrid>
                                <VSDeleteButton {...props} />
                                {/* <TPAEditButton setHospitalDetEdit={setgridRecordEdit} /> */}
                                <EditDocument setRecordEdit={setRecordEdit} />
                                <ReferenceField source="typeId" reference="lookups" label="License"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="licNo"  label="License No"   />
                                <TextField label="Document Name" source="imageLegacyName" />
                                <DisplayImage label="" source="url"/>
                                <DisplayFileLink label="" source="url"/>
                                
                                
                            </Datagrid>
                        </ReferenceManyField>
          </FormTab>}
          {typeId==1 && <FormTab label="Image Detail ">
                        <Button variant="contained" onClick={() => { setIsLicense (true); setIsImageType(115) }}
                            color="secondary">
                            Add Image 
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="account-image-maps"
                            perPage={500}
                            filter={{ imageTypeId:115}}
                            target="accountId">
                            <Datagrid>
                                <VSDeleteButton {...props} />
                                
                                <ReferenceField source="typeId" reference="lookups" label="Image Type"  link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField label="Document Name" source="imageLegacyName" />
                                <DisplayImage label="" source="url"/>
                                <DisplayFileLink label="" source="url"/>
                                
                                
                            </Datagrid>
                        </ReferenceManyField>
          </FormTab>}
          { <FormTab label="Ledger Detail ">
                        <Button variant="contained" onClick={() => { setIsAddLedger(true);  }}
                            color="secondary">
                            Add Ledger
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="account-docs"
                            perPage={500} 
                            target="accountId">
                            <Datagrid>
                                <VSDeleteButton {...props} />
                                
                                <ReferenceField source="typeId" reference="lookups" label="Dcoumtent Type"  link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField label="Remarks" source="remarks" /> 
                                <DisplayDocFileLink label="" source="docUrl"/>
                                
                                
                            </Datagrid>
                        </ReferenceManyField>
          </FormTab>}
        </TabbedForm>


    </Edit>
    <Drawer
     anchor="right"
     onClose={handleClose}
     classes={{ paper: myclasses.drawerPaper, }}
     open={ isAddress || isLicense ||isAddLedger } >
       {isAddress &&  <AccountAddressFields
                      onClose={closeEdit}
                      onCancel={onCancel}
                      accountId={props.id}
                      {...props} />}
      {isLicense &&  <DocumentsCreate
                      onClose={closeEdit}
                      onCancel={onCancel}
                      accountId={props.id}
                      imageTypeId={isImageType}
                      {...props} />}
      {isLicense &&  <DocumentsCreate
                      onClose={closeEdit}
                      onCancel={onCancel}
                      accountId={props.id}
                      imageTypeId={isImageType}
                      {...props} />}

{isAddLedger &&  <AccountDocCreate
                      onClose={closeEdit}
                      onCancel={onCancel}
                      accountId={props.id}
                      
                      {...props} />}


       
       </Drawer>
 
</React.Fragment>
  );
};
