import React from "react";
import { DisplayDate } from '../../common/DisplayDate';
import * as apiClient from "../../common/apiClient";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
  useNotify,
  AutocompleteInput
} from "react-admin";
import Button from '@material-ui/core/Button';
import { useRefresh } from 'react-admin';

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Goods Inward No  "
      source="goodsInwardNo~like"
      alwaysOn
    />
    <ReferenceInput label="Supplier" fullWidth={true} 
          perPage={5000} source="vendorId"
          
          reference="suppliers" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="supplierName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput> 
    <ReferenceInput label="Warehouse" 
          perPage={5000} source="branchId"
          filter={{ userId:localStorage.getItem("userId") }}
          reference="company-branches" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="name" variant="outlined"
           shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>  
  </Filter>
);
export const GoodsInwardOrderList = (props) => {
  const notify = useNotify();
    const refresh = useRefresh();
  const Approve = (props:any) => {
    // console.log(props, "===PROPS===");
    
    let statusId=props.record.statusId
    if(statusId===137)
      {
        return <Button variant="contained" color="primary"
        onClick={() => { handleSubmit(props.record.id)
         }}
         >
           Approve
         </Button>
      }
    else
      {
        return null;
      }
    
    }
    const handleSubmit = async (id) => {
      
      let userId;
      userId = localStorage.getItem("userId");
      if (userId) {
        userId = parseInt(userId);
      }
      
       let data: any = {};
       data.userId=userId;
       data.id=id;
      
      apiClient.approveGrn( data).then(res => {
        if (res[0].result) {
          notify(res[0].message)
          refresh()
        }
        else {
          notify(res[0].message, "warning")
  
        }
        
      }).catch(err => {
        console.log(err);
      });
    }
      
   
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Goods Inward List" filters={<Filters />}  >
      <Datagrid rowClick={false}>
        {propsObj.hasEdit && <EditButton />}
        {propsObj.hasEdit && <Approve label="Approve" />}
        <TextField source="goodsInwardNo" label="Goods Inward No  " /> 
        <ReferenceField source="statusId" reference="lookups" label="Status"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="inwardTypeId" reference="lookups" label="Inward Type"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="vendorId" reference="accounts" label="Supplier/Franchise"  link={false}>
                <TextField source="accountName" />
            </ReferenceField>
            <ReferenceField source="branchId" reference="company-branches" label="Warehouse"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
