import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";



export const AdvisoryPanelList = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
    if(!propsObj.hasDelete)
    {
      propsObj.bulkActionButtons=false;
    }
  }
  const DisplayImage = (props) => {
    let { record, source } = props;
    if (source && record[source] && record[source].length > 0)
      return (
        <div style={{width:"50px",height:"50px"}}> 
          <img                    src={record[source]}
                        width="100%"
                        height="100%"
                        
          /> </div>
      )
    else {
      return null
    }
  }
  const Filters = (props) => (
    <Filter {...props} variant="outlined">
      <SearchInput
        variant="outlined"
        label=" Name"
        source="name~like"
        alwaysOn
      />
    </Filter>
  );
  return (
    <List {...propsObj} exporter={propsObj.hasExport}  title="Advisory Panel"  filters={<Filters />}>
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        
        <TextField source="name" label="Name" />
        <TextField source="nameHindi" label="Name Hindi" /> 
        <TextField source="displayOrder" label="Display Order" /> 
        
        <DisplayImage label="" source="url"/> 
         <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Updated Date" locales="fr-FR" />
            </Datagrid>      
    </List>
  );
};
