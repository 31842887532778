import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  EditButton,
  Filter,
  ReferenceInput,
  SearchInput,
  AutocompleteInput,
  DateInput
} from "react-admin";

const Filters = props => (
  <Filter {...props} variant="outlined">
    

    {/* Add employee filter */}
    <ReferenceInput 
  label="Employee" 
  source="userId" 
  reference="users" 
  alwaysOn 
  allowEmpty
  filterToQuery={searchText => ({ 'firstName~like': searchText })}
  sort={{ field: 'firstName', order: 'ASC' }}
  filter={{ typeId: 270 }}
>
  
  <AutocompleteInput  
  optionText="firstName"
  
   shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
</ReferenceInput>


    {/* Add Date filter */}
    <DateInput label="Date" source="date" />
    
  </Filter>
);

export const AccountList = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = false;//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  let typeId = new URLSearchParams(props.location.search).get("type");
  if (!typeId) {
    typeId = '0';
  }
  localStorage.setItem("accounttype", typeId);

  return (
    <List {...propsObj} exporter={propsObj.hasExport} 
      bulkActionButtons={false} 
      filters={<Filters />}
      title="Daily Expense"
    >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' : false}>
        {propsObj.hasEdit && <EditButton />}
        
        <ReferenceField source="userId" reference="users" label="Employee" link={false}>
          <TextField source="firstName" />
        </ReferenceField>
        <DateField source="date" label="Date" locales="fr-FR" />
        
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="createdDate" label="Created Date" locales="fr-FR" />
        
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DateField source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>      
    </List>
  );
};
