import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,AutocompleteInput
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Purchase No "
      source="purchaseNo~like"
      alwaysOn
    />
    <ReferenceInput label="Supplier" fullWidth={true} 
          perPage={5000} source="vendorId"
          
          reference="suppliers" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="supplierName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput> 
    <ReferenceInput label="Warehouse" 
          perPage={5000} source="branchId"
          filter={{ userId:localStorage.getItem("userId") }}
          reference="company-branches" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="name" variant="outlined"
           shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
        </ReferenceInput>  
  </Filter>
);
export const PurchaseOrderList = (props) => {
 
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Purchase Order List" filters={<Filters />}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        
        <TextField source="purchaseNo" label="Purchase No  " />
        
        <ReferenceField source="purchaseTypeId" reference="lookups" label="Purchase Type"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="vendorId" reference="accounts" label="Supplier/Franchise"  link={false}>
                <TextField source="accountName" />
            </ReferenceField>
            <ReferenceField source="branchId" reference="company-branches" label="Warehouse"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
