import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";

const Filters = props => (
  <Filter {...props} variant="outlined">
      <SearchInput variant="outlined" label="Name" source="accountName~like" alwaysOn />
      <SearchInput variant="outlined" label="Mobile " source="mobilePhone~like" alwaysOn />
      
  </Filter>
);

export const AccountList = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  let typeId= new URLSearchParams(props.location.search).get("type")
  
  
  if(typeId)
  {
    typeId=typeId;
  }
  else
  {
    typeId='0';
  }
  localStorage.setItem("accounttype", typeId);

  return (
    <List {...propsObj} exporter={propsObj.hasExport} 
    
    filter={{ accountType: parseInt(typeId)===1?101:100 }}
    bulkActionButtons={false} 
    filters={<Filters />}
    title={ parseInt(typeId)===1?"Franchise List":"Supplier List"} 
    >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        
        <TextField source="accountName" label={parseInt(typeId)===1?"Franchise ":"Supplier "} />
        <TextField source="mobilePhone" label={"Mobile"} />
        
         <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Updated Date" locales="fr-FR" />
            </Datagrid>      
    </List>
  );
};
