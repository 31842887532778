import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';


import EditIcon from '@material-ui/icons/Edit';
import {
  Edit,
  TabbedForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab,
  useRefresh,
  ReferenceManyField, ReferenceField, Datagrid,
  TextField,
  DateField,
  NumberInput,
  FormDataConsumer,BooleanField
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import VSDeleteButton from "../../VSDeleteButton";
import { useStyles } from "../../formStyles";
import { Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CDeleteButton from "../../common/CDeleteButton";
import AccountAddressFields from "./EditExpense";
import DocumentsCreate from "./docs/DocumentsCreate";
import { TPAEditButton } from './AccountCreate';
import EditExpense from './EditExpense';

const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 650,
  },
}));

const DisplayFileLink = (props) => {
  let { record } = props;

  if (record.expenseDocument1 && record.expenseDocument1.length > 0)
    return (
      <a href={record.expenseDocument1}>View File</a>
    )
  else {
    return null
  }
}
const DisplayFileLink2 = (props) => {
  let { record } = props;

  if (record.expenseDocument2 && record.expenseDocument2.length > 0)
    return (
      <a href={record.expenseDocument2}>View File</a>
    )
  else {
    return null
  }
}

const DisplayImage = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <div style={{ width: "100px", height: "100px" }}>
        <img src={record[source]}
          width="100%"
          height="100%"

        /> </div>
    )
  else {
    return null
  }
}
export const userStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  item: {
    width: 100,
  },
}));

export const EditDocument = props => {

  const setRecordEdit = () => {
    props.setRecordEdit(props.record.id);
  }
  return (
    <Button
      color="secondary" disableElevation
      size="medium"

      onClick={setRecordEdit}
      startIcon={<EditIcon />}>Edit</Button>
  )
};

export const AccountEdit = (props) => {
  const history = useHistory(); // instead of useNavigate


  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = false;// (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  let typeId = localStorage.getItem("accounttype");

  if (typeId) {
    typeId = typeId;
  }
  else {
    typeId = 0;
  }
  const classes = useStyles({});

   
  const [isRecordEdit, setIsRecordEdit] = useState(false);
  
  const [selectedRecord, setSelectedRecord] = useState(null);
  
  const myclasses = myStyles({});
  const refresh = useRefresh(); 
  
  const closeEdit = () => {
    setIsRecordEdit(false);
    setSelectedRecord(null)
    refresh();
  }


  const onCancel = () => {
    setIsRecordEdit(false);
    setSelectedRecord(null)
  }

  const handleClose = useCallback(() => {
    setIsRecordEdit(false);
    setSelectedRecord(null)

  }, []);
  
   const EditButton = props => {
     
    return (
        <Button
            color="secondary" disableElevation
            size="medium"
            onClick={()=>{setSelectedRecord(props.record.id);setIsRecordEdit(true)}}
           /// onClick={setHospitalDetId}
            startIcon={<EditIcon />}>Edit</Button>
    )
  };
  return (
    <React.Fragment>
      <Edit
        
        title={"Daily Expense"}
        {...propsObj}>

        <TabbedForm variant="outlined"
          
          toolbar={<CreateToolbar  {...propsObj} />}>

          <FormTab value="DETAILS" label={" Detail"}>
            <TextField source="bookingNo" formClassName={classes.one_5_input}/>
            <DateField source="scheduleDate" label="ScheduleDate" locales="fr-FR" formClassName={classes.two_5_input} />
            <ReferenceField source="slotId" reference="lookups" label="Slot" link={false} disabled   formClassName={classes.three_5_input} >
                  <TextField source="name" />
                </ReferenceField>
                
                <ReferenceField source="userFarmId" reference="user-farms" label="User Farm" link={false} disabled   formClassName={classes.four_5_input} >
                  <TextField source="farmName" />
                </ReferenceField>
                <div>

                </div>
                <BooleanField source="isResceduled" disabled  formClassName={classes.one_5_input}/>
                <ReferenceField source="recheduledBy" reference="users" label="Rec" link={false} disabled   formClassName={classes.two_5_input} >
                  <TextField source="firstName" />
                </ReferenceField>
                <DateField source="rescheduledDate" label="RescheduledDate" locales="fr-FR" formClassName={classes.three_5_input} />
                <ReferenceField source="rescheduleReasonId" reference="lookups" label="Reschedule Reason" link={false} disabled   formClassName={classes.four_5_input} >
                  <TextField source="name" />
                </ReferenceField>
                <div></div>
                <DateField source="actionDate" label="ActionDate" locales="fr-FR" formClassName={classes.one_5_input} />
                <ReferenceField source="actionStatusId" reference="lookups" label="Action Status" link={false} disabled   formClassName={classes.two_5_input} >
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="actionNotes"  formClassName={classes.three_5_input} />
                <DateField source="actionCompletionDate" label="ActionCompletionDate" locales="fr-FR" formClassName={classes.four_5_input} />
                <ReferenceField source="statusId" reference="lookups" label="Status" link={false} disabled   formClassName={classes.last_5_input} >
                  <TextField source="name" />
                </ReferenceField>
             <ReferenceInput label="AssignedTo" validate={[required()]}
                                     
                                     fullWidth={true}
                                     perPage={5000} source="assignedTo"
                                     sort={{ field: 'firstName', order: 'ASC' }}
               filter={{ typeId: 270 }}
             
                                     reference="users" >
                                     <SelectInput optionText="firstName" variant="outlined" fullWidth={true} />
                                 </ReferenceInput>
                                 <DateInput validate={[required()]} source="assignedDate" label="AssignedDate" fullWidth={true}/>
                                 <div></div>
                                 <TextInput source="assignTaskNotes" fullWidth={true} />
            
                
          </FormTab>



        </TabbedForm>


      </Edit>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isRecordEdit } >
        
        {isRecordEdit && <EditExpense
          onClose={closeEdit}
          onCancel={onCancel}
          expenseDetailId={selectedRecord}
          
          {...props} />}
 
      </Drawer>

    </React.Fragment>
  );
};
