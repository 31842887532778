import {AccountList} from "./AccountList";
import {AccountEdit} from "./AccountEdit";
import {AccountCreate} from "./AccountCreate";


export default {
    list: AccountList,
   // create: AccountCreate,
    edit: AccountEdit,
};
