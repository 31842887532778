import React, { useState,useEffect } from "react";
import * as apiClient from "../../common/apiClient"; 

import {
  useRedirect,
  Edit,
  DateInput,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify

} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";

import { GridDetail } from './GridDetail';

export const PurchaseOrderCreate = (props) => {
  const classes = useStyles({});
  const notify = useNotify();
  const redirect=useRedirect();
  const [gridData, setGridData] = useState([{ lineNumber: 1, productCode:null , productName: null, taxPer:0,totalTaxAmt:null, hsn:"", uomId: 0,mrp:null, price: null, qty: null, totalAmount: null}]);
  const [taxType, setTaxType] = useState(0);
  const [address, setAddress] = useState('');
  

  useEffect(() => {
  setGridData(gridData) 
  });
  
  const handleChange = (indata) => { 
     setGridData(indata);
  }

  const loadTaxType = async (e,type) => {
    
    await apiClient.getSuppilerdata(e).then(res => { 
      setTaxType(res.gstType);  
    }).catch(err => {
      console.log(err);
    });

    
    await apiClient.getdata_v1(`/suppliers/getAddress/${e}`).then(res => { 
      setAddress(res[0].address);  
    }).catch(err => {
      console.log(err);
    });
    
  }
 
  
  const handleSubmit = async (formdata) => {
        //alert("c");
        debugger
    let userId;
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }

    let data:any={};
    data.id=0;   
    data.branchId=formdata.branchId;
    data.vendorId=formdata.vendorId;
    data.documentNo=formdata.documentNo;
    data.purchaseDate=formdata.purchaseDate;
    data.taxType=taxType; 
    data.remarks=formdata.remarks; 
    data.supplyDate=formdata.supplyDate; 
    data.paymentModeId=formdata.paymentModeId; 
    data.purchaseTypeId=formdata.purchaseTypeId;
    
    data.lineItems=[...gridData];
    // let m = moment(new Date());
    
    // data. modifyDate=  m.toISOString()
     data.createdBy=  userId
    apiClient.addeditPurchaseOrder( data).then(res => {
      if(res[0].id>0)
      {
        notify(res[0].remarks)
        redirect("/purchase-orders");
      }
      else
      {
        notify(res[0].remarks,"warning")

      }
        //console.log(res);
        //props.onClose();
    }).catch(err => {
        console.log(err);
    });
}
  return (
    <Create title="Purchase Order Create" {...props}>
      <SimpleForm redirect="list" onSubmit={handleSubmit} 
      toolbar={<FormToolbar  onSave={handleSubmit}  {...props} showDelete={false} />} variant="outlined" >
        <div className="transaction-header">
          Purchase Order Detail
        </div>
        <DateInput source={"purchaseDate"} label={"Purchase Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.one_4_input} initialValue={new Date()} />         
        <ReferenceInput label="Purchase Type" fullWidth={true} formClassName={classes.two_4_input}
          perPage={5000} source="purchaseTypeId"
          filter={{ type: 'PurType' }}
          reference="lookups" >
          <SelectInput optionText="name" variant="outlined"  validate={[required()]} initialValue={158}/> 
        </ReferenceInput>  
        
        
        <FormDataConsumer  fullWidth={true} formClassName={classes.three_4_input}>
          {({ formData, ...rest }) =>
            formData.purchaseTypeId &&
             <ReferenceInput label={formData.purchaseTypeId != 158?"Franchise":"Supplier"} fullWidth={true} formClassName={classes.three_4_input}
            perPage={5000} source="vendorId"
            onChange={(e)=>loadTaxType(e,formData.purchaseTypeId)}
            filter={{ accountType: formData.purchaseTypeId != 158?101:100 }}
            reference="accounts" > 
            <AutocompleteInput optionText="accountName" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
            </ReferenceInput> 
        
          }
        </FormDataConsumer>
        
        <ReferenceInput label="Warehouse" fullWidth={true} formClassName={classes.last_4_input}
          perPage={5000} source="branchId"
          filter={{ userId:localStorage.getItem("userId") }}
          reference="company-branches" >
          {/* <SelectInput optionText="name" variant="outlined"  validate={[required()]} /> */}
          <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} validate={[required()]} />
        </ReferenceInput>  
        
        <TextInput source="documentNo" label="Reffernce Document No" initialValue={""} fullWidth={true} formClassName={classes.one_4_input} />
        <DateInput source={"supplyDate"} label={"Supply Date"} fullWidth={true} validate={[required()]} variant="outlined" formClassName={classes.two_4_input} />         
         
        <SelectInput source="paymentModeId" label="Payment Mode " fullWidth={true} formClassName={classes.three_4_input} 
                    choices={[
                        { id: 1, name: 'Cash' },
                        { id: 2, name: 'Cheque' },
                        { id: 2, name: 'NEFT' } 
                    ]} />
       {address.length>0  &&  <div >
          <h6>Address:</h6><h6><span  dangerouslySetInnerHTML={{__html: address}}></span></h6>
        </div>
}
        <TextInput source="remarks" label="Remarks" initialValue={""} fullWidth={true}  />
        
        <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                 <GridDetail {...formDataProps} gridData={gridData} taxType={taxType}  handleChange ={handleChange}/>
              )}
            </FormDataConsumer>
             
            
              </SimpleForm>
    </Create>
  );
};
