import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField,
  ImageInput

} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const ProductEdit = (props) => {
  const classes = useStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  
  return (
    <Edit {...propsObj}  title="Action Edit">
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
                
      <TextInput  source="productName" label="Product Name " validate={[required()]} fullWidth={true} formClassName={classes.first_inline_input} /> 
          <TextInput  source="subCategoryName" label="Sub Category " validate={[required()]} fullWidth={true} formClassName={classes.last_inline_input} />
          <TextInput  source="productNameHindi" label="Product Name Hindi " validate={[required()]} fullWidth={true} formClassName={classes.first_inline_input} /> 
          <TextInput  source="subCategoryNameHindi" label="Sub Category  Hindi " validate={[required()]} fullWidth={true} formClassName={classes.last_inline_input} /> 
          <TextInput multiline  source="description" label="Description "   fullWidth={true} formClassName={classes.first_inline_input} /> 
          <TextInput multiline  source="descriptionHindi" label="Description Hindi "   fullWidth={true} formClassName={classes.last_inline_input} /> 

          <ReferenceInput label="Manufacture " formClassName={classes.one_4_input} 
                    perPage={5000} source="manufactureId" 
                    fullWidth={true}
                    reference="manufactures" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.one_4_input}  />
          </ReferenceInput>
          <ReferenceInput label="Product Family " formClassName={classes.two_4_input} 
                    perPage={5000} source="productFamilyId" 
                    fullWidth={true}
                    reference="product-families" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.two_4_input}  />
          </ReferenceInput>
          <ReferenceInput label="Product Group " formClassName={classes.three_4_input} 
                    perPage={5000} source="productGroupId" 
                    fullWidth={true}
                    reference="product-groups" >
                    <SelectInput optionText="groupName" variant="outlined"  fullWidth={true} formClassName={classes.three_4_input}  />
          </ReferenceInput>
          <ReferenceInput label="Market Country " formClassName={classes.last_4_input} 
                    perPage={5000} source="marketCountryId" 
                    fullWidth={true}
                    reference="countries" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.three_4_input}  />
          </ReferenceInput>
          <ReferenceInput label="Tax Type " formClassName={classes.one_4_input} 
                    perPage={5000} source="taxHeadId" 
                    fullWidth={true}
                    reference="tax-heads" >
                    <SelectInput optionText="taxName" variant="outlined"  fullWidth={true} formClassName={classes.one_4_input}  />
          </ReferenceInput>
          
                   
          <NumberInput source="taxPer" fullWidth={true}  label="Tax Per" formClassName={classes.two_4_input} initialValue={0}/>
          <NumberInput source="price" fullWidth={true}  label="Price" formClassName={classes.three_4_input} />
          <NumberInput source="sellingPrice"  fullWidth={true} label="Selling Price" formClassName={classes.last_4_input} />

          
          <TextInput  source="barCodeNumber" label="Barcode " fullWidth={true} formClassName={classes.one_4_input}  /> 
          <ReferenceInput label="UOM" formClassName={classes.two_4_input} 
                    perPage={5000} source="uomId" 
                    fullWidth={true}
                    validate={[required()]} 
                    reference="uoms" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.two_4_input}  />
          </ReferenceInput>
          <ReferenceInput label="HSN" formClassName={classes.three_4_input} 
                    perPage={5000} source="hsnGroupId" 
                    fullWidth={true}
                    validate={[required()]} 
                    reference="hsn-groups" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.last_4_input}  />
          </ReferenceInput>
          <ReferenceInput label="Discount" formClassName={classes.last_4_input} 
                    perPage={5000} source="discountId" 
                    fullWidth={true}
                     
                    reference="discounts" >
                    <SelectInput optionText="discountName" variant="outlined"  fullWidth={true} formClassName={classes.last_4_input}  />
          </ReferenceInput>
          <ReferenceInput label="Pack" formClassName={classes.one_4_input} 
                    perPage={5000} source="packUnit" 
                    fullWidth={true}
                    validate={[required()]} 
                    reference="uoms" >
                    <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.one_4_input}  />
          </ReferenceInput>

          <NumberInput source="packQty"  fullWidth={true} label="Pack Qty" formClassName={classes.two_4_input} initialValue={1}/>
          <NumberInput source="deliveryCharge"  fullWidth={true} label="Delivery Charge" formClassName={classes.three_4_input} initialValue={0}/>
          <BooleanInput source="isFreeDelivery" validate={[required()]}  label="Free Delivery" formClassName={classes.three_4_input} initialValue={false}/>
          <BooleanInput source="isFeatureed" validate={[required()]}  label="Is Featureed" formClassName={classes.last_4_input} initialValue={false}/>
          <BooleanInput source="isRefundable" validate={[required()]}  label="is Refundable" formClassName={classes.one_4_input} initialValue={false}/>

          <TextInput  source="otherDescription" label="Other Description" multiline fullWidth={true} formClassName={classes.two_4_input} initialValue={""} /> 
          <TextInput  source="otherDescriptionHindi" label="Other Description Hindi" multiline fullWidth={true} formClassName={classes.three_4_input} initialValue={""} /> 
          <ReferenceInput label="Apply Unit" formClassName={classes.two_4_input} 
                              perPage={5000} source="applyUnitId" 
                              fullWidth={true}
                              validate={[required()]} 
                              reference="uoms" >
                              <SelectInput optionText="name" variant="outlined"  fullWidth={true} formClassName={classes.two_4_input}  />
                    </ReferenceInput>
          <div style={{minWidth:"100%"}}></div>
          <ImageField source="url" title="English Url" formClassName={classes.first_inline_input} />
          <ImageInput
              source="englishUpurl"
              
              formClassName={classes.last_inline_input}
              label="Product Image "
              accept="image/*"
              className="logourl"
            >
              <ImageField source="englishUpurl" title="Icon" />
            </ImageInput>
      </SimpleForm>
    </Edit>
  );
};
