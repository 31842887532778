import React, { useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const DetailCreate = props => {
    const classes = useStyles({});
    const [typeId, setTypeId] = useState(124)
    const initialValues = {
        calimOrdId: props.calimOrdId,
        name: "",
        nameHindi: ""

    }
    const handleSubmit = async (formdata) => {
        //console.log(formdata);
        let m = moment(new Date());
        let files = { englishUrl: '', hindiUrl: '' };

        if (typeId == 122) {
            await apiClient.UploadFileV1("englishUpUrl", formdata.englishUpUrl).then(res => {
                debugger
                let documentUrl = res;
                files.englishUrl = documentUrl.url


            }).catch(err => {
                console.log(err);
            });
            await apiClient.UploadFileV1("hindiUpUrl", formdata.hindiUpUrl).then(res => {
                debugger
                let documentUrl = res;
                files.hindiUrl = documentUrl.url


            }).catch(err => {
                console.log(err);
            });
            let data = {
                productDiseasesId: parseInt(props.id),
                typeId: 122,
                isActive: formdata.isActive,
                productId: parseInt(0),
                name: formdata.name,
                nameHindi: formdata.nameHindi,
                moreDetails: formdata.moreDetails,
                moreDetailsHindi: formdata.moreDetailsHindi,
                url: files.englishUrl,
                urlHindi: files.hindiUrl


            }


            if (!data.createdBy) data.createdBy = 1;
            if (!data.createdDate) data.createdDate = m.toISOString();
            data.modifyBy = 1;
            data.modifyDate = m.toISOString();
            apiClient.addEntiyRecord("product-diseases-sols", data).then(res => {
                //console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });

        }
        else if (typeId == 121) {
            let data = {
                productDiseasesId: parseInt(props.id),
                typeId: 121,
                isActive: formdata.isActive,
                name: formdata.name,
                productId: parseInt(0),
                nameHindi: formdata.nameHindi,
                moreDetails: formdata.moreDetails,
                moreDetailsHindi: formdata.moreDetailsHindi,
                url: formdata.englishUpUrl,
                urlHindi: ""


            }
            console.log(data)

            if (!data.createdBy) data.createdBy = 1;
            if (!data.createdDate) data.createdDate = m.toISOString();
            data.modifyBy = 1;
            data.modifyDate = m.toISOString();
            apiClient.addEntiyRecord("product-diseases-sols", data).then(res => {
                //console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });
        }
        else {
            let data = {
                productDiseasesId: parseInt(props.id),
                typeId: 124,
                isActive: formdata.isActive,
                name: formdata.name,
                productId: parseInt(formdata.productId),
                nameHindi: formdata.nameHindi,
                moreDetails: formdata.moreDetails,
                moreDetailsHindi: formdata.moreDetailsHindi,
                url: "NA",
                urlHindi: "NA"


            }
            console.log(data)

            if (!data.createdBy) data.createdBy = 1;
            if (!data.createdDate) data.createdDate = m.toISOString();
            data.modifyBy = 1;
            data.modifyDate = m.toISOString();
            apiClient.addEntiyRecord("product-diseases-sols", data).then(res => {
                //console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });

        }


    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                initialValues={ initialValues }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">
                <ReferenceInput perPage={ 2000 } label=" Type" source="typeId" reference="lookups" onChange={ (e) => setTypeId(e.target.value) }
                    fullWidth={ true } sort={ { field: 'name', order: 'ASC' } } filter={ { type: 'DIST' } } initialValue={ 124 }>
                    <SelectInput optionText="name" validate={ required() } />
                </ReferenceInput>
                { typeId == 124 ? <ReferenceInput perPage={ 2000 } label="Solution Product" source="productId" reference="products"
                    fullWidth={ true } sort={ { field: 'productName', order: 'ASC' } }>
                    <SelectInput optionText="productName" validate={ required() } disabled={ false } />
                </ReferenceInput> : null }
                { <TextInput source="name" label="Solution English Name" fullWidth={ true } /> }
                { <TextInput source="nameHindi" label=" Solution Name Hindi" fullWidth={ true } /> }

                { <TextInput source="moreDetails" multiline label="Solution English Description" fullWidth={ true } /> }
                { <TextInput source="moreDetailsHindi" multiline label="Solution Hindi Description" fullWidth={ true } /> }

                { typeId == 122 ? <FileInput fullWidth={ true } source="englishUpUrl" label="Image">
                    <ImageField source="englishUpUrl" title="File" />
                </FileInput>
                    : null

                }
                { typeId == 122 ? <FileInput fullWidth={ true } source="hindiUpUrl" label="Image">
                    <ImageField source="hindiUpUrl" title="File" />
                </FileInput>
                    :
                    null
                }
                { typeId == 121 ? <TextInput source="englishUpUrl" label=" Video URL" fullWidth={ true } validate={ [required()] } /> : null }

                <BooleanInput source="isActive" validate={ [required()] } label="Active" initialValue={ true } />

            </SimpleForm>
        </Create>
    );
}
export default DetailCreate;